p,
small,
span,
div,
b,
ul,
li,
td,
th,
tbody,
hr,
h1,
h2,
h3,
h4,
h5,
h6,
label,
select,
input,
button,
div,
a {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0em;
}
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1580px !important;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 96% !important;
}
/* html {
  scroll-behavior: smooth;
} */

html {
  scroll-behavior: auto; /* Disable smooth scrolling */
}
body {
  background: #f0f4f8 !important;
}