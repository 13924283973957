$font-size: 1rem;
$line-height: 1.4;
$lines-to-show-heading: 3;
$lines-to-show-text: 4;
$lines-to-show-author: 2;

@media only screen and (min-width: 0px) and (max-width: 992px) {
  .inner-page-section {
    padding: 50px 0px 50px 0px !important;
  }
  .form-title {
    font-size: 30px;
  }
  .pg-images-overlay {
    font-size: 1rem !important;
  }
  .image-container {
    width: 50% !important;
  }
}

.inner-page-section {
  padding: 70px;
  .form-title {
    color: #434343;
    font-size: 1.8rem;
    text-align: center;
  }
}

.image-container {
  position: relative;
  display: inline-block;

  height: auto;
  overflow: hidden;
}

.image-container:hover .overlay {
  bottom: 0;
}

.page-body-section {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding: 0px;
    .container {
      padding: 0;
    }
  }
  padding: 80px;
}

.section {
  padding: 80px;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding: 50px 0px 50px 0px;
  }
}

.pg-title-section {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding: 30px 10px !important;
    text-align: center;
  }
  display: flex;
  align-items: center;
  padding: 100px 100px !important;
}

.pg-form-section {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding: 30px 10px !important;
  }
  display: flex;
  align-items: center;
  padding: 0px 80px !important;
}

.p-about-text {
  .heading {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 1.5rem !important;
      margin-top: 15px !important;
    }
    font-weight: 500;
    font-size: 3rem;
    margin-bottom: 15px;
  }
  .text {
    font-size: 1.2rem;
    font-weight: 400;
  }
}

.p-page-text {
  .heading {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 1.5rem !important;
      margin-top: 20px !important;
    }
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 15px;
  }
  .text {
    font-size: 1.2rem;
    font-weight: 400;
  }
}

.highlighted-heading {
  display: inline-block;
  position: relative;
}

.highlighted-heading::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 4px;
  background-color: #30679d;
}

.hp-side-image {
  width: 100%;
  height: 580px;
  object-fit: cover;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    height: 280px;
  }
}

.hp-service-card {
  background: #fcfcfc;
  padding: 10px;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 10px;
  height: 320px;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    height: auto;
  }
}

.pb-title {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    font-size: 2rem;
  }
  font-weight: 600;
  font-size: 3rem;
}

.pg-images-overlay {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: flex-end;
  background: #00000008;
  z-index: 1;
  color: #ffff;
  font-size: 2rem;
  font-weight: 500;
  padding: 20px;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  background: -moz-linear-gradient(
    bottom,
    #000 0%,
    rgba(255, 255, 255, 0) 49%,
    rgba(255, 255, 255, 0) 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left bottom,
    top bottom,
    color-stop(0%, #000),
    color-stop(49%, rgba(255, 255, 255, 0)),
    color-stop(100%, rgba(255, 255, 255, 0))
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    #000 0%,
    rgba(255, 255, 255, 0) 49%,
    rgba(255, 255, 255, 0) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #000 0%,
    rgba(255, 255, 255, 0) 49%,
    rgba(255, 255, 255, 0) 100%
  );
  /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    #000 0%,
    rgba(255, 255, 255, 0) 49%,
    rgba(255, 255, 255, 0) 100%
  );
  /* IE10+ */
  background: linear-gradient(
    to top,
    #000 0%,
    rgba(255, 255, 255, 0) 49%,
    rgba(255, 255, 255, 0) 100%
  );
}

.scroll-back_cards {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: none;
  }
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 5px;
  position: absolute;
  left: 0;
  z-index: 111;
}
.scroll-next_cards {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: none;
  }
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 5px;
  position: absolute;
  right: 0;
  z-index: 111;
}
.fp-contact-card {
  text-align: center;
}

.cardItem {
  height: auto !important;
  padding: 0px !important;
  position: relative;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    width: 85% !important;
  }
}
.carousel-hp {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    ul {
      justify-content: start !important;
    }
  }
}

.hp-card-title {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  position: relative;
  width: 100%;
}
