.page-not-found {
  .text {
    font-size: 1.2rem;
    padding: 0;
    text-align: center;
    border: none;
    background-color: #fff0;
    font-weight: 300 !important;
    margin: 0px;
  }
}
