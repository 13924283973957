.Footer-page {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .fp-mhide {
      display: none !important;
    }
    .fp-mp{
      padding-bottom: 10px !important;
    }
    .address-footer{
      justify-content: center;
    }
  }
  .ftr-time-section {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      justify-content: center !important;
    }
  }
  .ftr-time-card {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      text-align: center !important;
      padding-bottom: 10px;
    }
  }

  .Footer-menu-card {
    display: flex;
    color: #3e3f40;
    justify-content: space-between;
    padding: 15px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
      text-align: center;
    }
  }
  .Footer-menu-subcard {
    display: flex;
    align-items: center;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
    }
  }
  .Footer-menu-card1 {
    background: #fff0;
    white-space: normal;
    overflow: auto;
    padding: 40px 0px 0px 0px;
    border-top: 1px solid #e3e4e4ee;
  }
  .Footer-menu-card01 {
    background: #3d2b93;
  }
  .Footer-menu-card01 {
    display: flex;
    align-items: center;
    color: #3e3f40;
    justify-content: space-around;
    padding: 20px;
    white-space: normal;
    overflow: auto;
  }
  .Footer-menu-item {
    font-size: 16px;
    font-weight: 500;
    h5 {
      font-size: 1.5rem;
      color: #3e3f40;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding-bottom: 20px;
    }
  }
  .Footer-menu-SubItem {
    svg{
      color: #3e3f40;
    }
    font-size: 1rem;
    color: #3e3f40;
    padding: 5px 5px 5px 5px;
    font-weight: 400;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
      padding: 10px;
    }
    img {
      padding-bottom: 20px;
    }
    a {
      color: #3e3f40;
      font-size: 1rem;
      font-weight: 400;
    }
    a:hover {
      color: #3e3f40;
    }
  }
  .Footer-menu-socia-SubItem {
    padding: 5px 5px 5px 5px;
    font-weight: 300;
    img {
      padding-bottom: 20px;
    }
    a {
      color: #444;
      display: flex;
      align-items: center;
      font-size: 28px;
      padding-right: 15px;
    }
  }
  .Footer-menu-social {
    display: flex;
    align-items: center;
    padding-top: 15px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      justify-content: center;
    }
  }
  .Footer-cp {
    text-align: right;
    color: #8e8e8e;
    font-size: 12px;
  }
}
.Footer-menu-bottom {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: block !important;
  }
  display: flex;
  width: 100%;
  .Footer-menu-SubItem {
    font-size: 14px;
    padding-right: 20px;
    color: #3e3f40 !important;
    font-weight: 400 !important;
  }
  .Footer-menu-SubItem:last-child {
    padding-right: 0 !important;
  }
}

.footer-app-icons {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    justify-content: center;
  }
}

.fp-social-icon{
  font-size: 3rem !important;
  filter: saturate(0.3);
  border: 1px solid #eee;
  padding: 7px;
  background: #ebebeb;
  border-radius: 100px;
}