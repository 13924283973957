.page-section {
  .page-sub-section {
    margin-left: 240px;
    padding-top: 55px;
    padding-bottom: 20px;
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .page-sub-section {
      padding-left: 0;
    }
  }
}