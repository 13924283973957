.p-html-details{
  ul{
    padding-left: 15px;
  }
}
.PhoneInput{
  input{
  background: rgb(248, 249, 250) !important;
  border: none !important;
  color: rgb(0, 0, 0) !important;
  height: 50px !important;
  }
  input:hover{
    outline: none !important;
  }
  input:active{
    outline: none !important;
  }
  input:focus{
    outline: none !important;
  }
}
.pp-form-de{
  input{
    box-shadow: none !important;
  }
  textarea{
    box-shadow: none !important;
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}