.Header-section {
  top: 0;
  right: 0;
  left: 0;
  white-space: nowrap;
  z-index: 1111;
  position: -webkit-sticky;
  position: sticky;
  border-bottom: 1px solid #f1f1f1;
  padding: 7px;

  .Header-menu {
    overflow: auto;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      overflow: auto;
      display: flex;
      align-items: center;
      .container {
        padding: 0;
      }
    }
  }

  .Header-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .mobile-hide {
      display: none !important;
    }
  }

  .nav-menu-item:hover {
    color: #30679d !important;
  }
  .nav-menu-item {
    padding: 7px 1.5rem 7px 1.5rem;
    font-size: 1.1rem;
    text-transform: capitalize;
    font-weight: 500;
    small {
      color: #fcfcfc;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    a {
      display: flex;
      align-items: center;
      position: relative;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 18px;
      padding: 7px 10px 7px 10px;
    }
  }

  .Header-menu-account {
    display: flex;
    align-items: center;
    margin: 0px 20px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      margin: 0px 0px;
    }
  }

  @keyframes Header-menu-account {
    from {
      display: block;
    }
    to {
      transform: translateY(40px);
      opacity: 0;
    }
  }
}

a:hover {
  text-decoration: none !important;
}

.row {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mobile-menu-item-size {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    font-size: 12px !important;
    padding-left: 5px;
  }
}

.Header-menu-logoName {
  cursor: pointer;
  position: relative;
  display: flex;
  padding: 5px;

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    font-size: 12px;
    display: flex;
    align-items: center;
    height: auto;
    font-weight: 700;
    width: 100%;
    text-align: left;
    margin: 0px 15px;
    .scrolling-img {
      width: 40px !important;
    }
    .scrolling-img-rev {
      width: 60px !important;
    }
  }

  svg {
    position: absolute;
    left: -7px;
    top: 0px;
    transform: rotate(360deg);
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 25px;
    }
  }

  .name {
    color: #2686c5;
    letter-spacing: 1px;
    font-weight: 500;
  }
  .slug {
    color: #373839;
    letter-spacing: 1px;
    font-weight: 500;
  }
  .scrolling-img {
    margin-top: 0px !important;
  }
}

.side-menu-section {
  .MuiDrawer-content {
    width: 240px;
  }
}

.Header-menu-card {
  display: flex;
  align-items: center;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    flex-direction: row-reverse;
  }
}
